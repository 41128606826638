<template>
    <section id="podcast-list" class="component-container white">
        <div :id="cmsBlock.anchorTag" class="podcasts">
            <div v-if="cmsBlock.useGroups">

                <div class="group-buttons">
                    <button v-for="podcastGroup in podcastGroups" class="button-dark"
                        :class="{ active: podcastGroup == activePodcastGroup }" v-on:click="activate(podcastGroup)">
                        {{ podcastGroup.groupTitle }}
                    </button>
                </div>

                <PodcastList v-for="podcastGroup in podcastGroups" :key="podcastGroup.id"
                    :podcasts="getPaginatedPodcasts(podcastGroup.podcasts)" :active="podcastGroup == activePodcastGroup" />
            </div>

            <PodcastList v-else :podcasts="getPaginatedPodcasts(cmsBlock.podcasts)" :active="true" />

            <Pagination v-if="total > 1" path="/podcast" :total="total" :page="page" scrollAnchor="podcast-list" />
        </div>

    </section>
</template>

<script>
import PodcastList from '@/components/PageBuilder/Elements/PodcastList'
import Pagination from '@/components/Pagination'

export default {
    name: 'MultiplePodcastsBlock',

    props: [
        'cmsBlock'
    ],

    components: {
        PodcastList,
        Pagination
    },

    data() {
        return {
            podcastGroups: [],
            activePodcastGroup: null,
            limit: 12
        }
    },

    mounted() {
        this.buildPodcastGroups()
        this.initActivePodcastGroup()
    },

    computed: {
        page() {
            return this.$route.query.page ? this.$route.query.page : 1
        },

        group() {
            return this.$route.query.group ? this.$route.query.group : 'all'
        },

        total() {
            if (this.cmsBlock.useGroups) {
                if (this.activePodcastGroup) {
                    return Math.ceil(this.activePodcastGroup.podcasts.length / this.limit)
                }
            }
            else {
                return Math.ceil(this.cmsBlock.podcasts.length / this.limit)
            }
        }
    },

    methods: {
        activate(podcastGroup) {
            this.activePodcastGroup = podcastGroup
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    group: podcastGroup.groupCode,
                    page: 1
                }
            })
        },

        sortPodcasts(podcasts) {
            podcasts.sort(function (a, b) {
                var aDate = new Date(a.attributes.date)
                var bDate = new Date(b.attributes.date)
                if (bDate > aDate) {
                    return 1
                } else {
                    return -1
                }
            })
            return podcasts
        },

        buildPodcastGroups() {
            var self = this

            if (this.cmsBlock.showAll) {
                this.buildAllPodcastsGroup()
            }

            this.cmsBlock.PodcastGroup.forEach(function (group) {
                var podcasts = [...group.podcasts.data]
                self.podcastGroups.push({
                    id: group.id,
                    podcasts: self.sortPodcasts(podcasts),
                    groupCode: group.groupCode,
                    groupTitle: self.localize(group.groupTitle)
                })
            })
        },

        buildAllPodcastsGroup() {
            // Build a podcast array with podcasts from all groups.
            // Use an object with podcast ids as keys
            // to prevent duplicates in the 'all' group
            var podcasts = {}
            this.cmsBlock.PodcastGroup.forEach(function (group) {
                group.podcasts.data.forEach(function (podcast) {
                    podcasts[podcast.id] = podcast
                })
            })
            this.podcastGroups.push({
                id: 0,
                podcasts: this.sortPodcasts(Object.values(podcasts)),
                groupCode: 'all',
                groupTitle: this.$t('allPodcasts')
            })
        },

        initActivePodcastGroup() {
            var self = this
            this.podcastGroups.forEach(function (group) {
                if (group.groupCode == self.group) {
                    self.activePodcastGroup = group
                }
            })
        },

        getPaginatedPodcasts(podcasts) {
            if (podcasts.length > 0) {
                if (this.page <= this.total) {
                    var start = (this.page - 1) * this.limit
                    var end = start + this.limit
                    return podcasts.slice(start, end)
                } else {
                    // reset page to 1
                    this.$router.push({
                        path: this.$route.path,
                        query: {
                            ...this.$route.query,
                            group: this.group,
                            page: 1
                        }
                    })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .podcasts {
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }

        .group-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: getSpacing('padding-default-vertical', 'desktop');

            @include breakpoint('tablet') {
                margin-bottom: getSpacing('padding-default-vertical', 'tablet');
            }
            @include breakpoint('mobile') {
                margin-bottom: getSpacing('padding-default-vertical', 'mobile');
            }
        }

        .button-dark {
            margin: 15px;

            &.active {
                color: white;
                background: $timberGreen;
            }
        }
    }
}
</style>
