<template>
    <div>
        <div class="list" :class="{active: active}">
            <div class="podcast" v-for="(podcast, index) in podcasts">
                <router-link :to="'/podcast/' + podcast.attributes.slug" :key="podcast.id">
                <picture>
                    <template v-for="[format, set] of Object.entries(srcsets[index])">
                        <source v-if="format !== originalFormats[index]" :srcset="set" sizes="(max-width: 1100px) 90vw, 30vw" :type="'image/' + format.slice(1)">
                    </template>
                    <img loading="lazy" decoding="async" :srcset="srcsets[index][originalFormats[index]]" :type="'image/' + originalFormats[index].slice(1)" sizes="(max-width: 1100px) 90vw, 30vw" :alt="localize(podcast.attributes.image).alternativeText">
                </picture>
                </router-link>
                
                
                <!--<PodcastPlayer :podcastCode="podcast.attributes.iFrameCode"/>
                <div class="details">
                    <div class="date">
                        {{ podcastDate(podcast) }}
                    </div>
                    <div class="title">
                        {{ localize(podcast.attributes.title) }}
                    </div>
                    <div class="description">
                        {{ localize(podcast.attributes.description) }}
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import PodcastPlayer from '@/components/PageBuilder/Elements/PodcastPlayer'
    import { dateFormat } from '@/helper'

    export default {
        name: 'PodcastList',

        props: [
            'podcasts',
            'active'
        ],

        components: {
            PodcastPlayer
        },

        computed: {
            srcsets(){
                let srcsets = []
                for (const podcast of this.podcasts) {
                    srcsets.push(this.getImageSrcsets(this.localize(podcast.attributes.image).data.attributes))
                }
                return srcsets
            },
            
            hasWebp(){
                let hasWebps = []
                for (const srcset of this.srcsets) {
                    hasWebps.push(srcset['.webp'] ? true : false)
                }
                return hasWebps
            },

            originalFormats(){
                let originalFormats = []
                for (const srcset of this.srcsets) {
                    for (const[format, _] of Object.entries(srcset)) {
                        if (format !== '.webp') {
                            originalFormats.push(format)
                        }
                    }
                }
                
                return originalFormats
            },
        },

        methods: {
            podcastDate(podcast) {
                return dateFormat(podcast.attributes.date)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .button {
        
    }

    .list {
        display: none;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px 24px;

        @include breakpoint('tablet') {
            grid-template-columns: 1fr 1fr;
        }
        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
        }

        &.active {
            display: grid;
        }
    }

    .podcast {
        min-width: 0;

        img {
            width: 100%;
        }
    }

    .details {
        flex-grow: 1;
        padding: 20px;
        background: $lightGrey;
        color: $timberGreen;
        word-break: break-word;
        hyphens: auto;
        height: 300px;
        overflow-y: scroll;

        @include breakpoint('mobile') {
            height: 250px;
        }

        .title {
            font-size: getFontSize('smallText', 'desktop');
            font-weight: bold;
            text-transform: uppercase;
            margin: 10px 0;

            @include breakpoint('tablet') {
                font-size: getFontSize('smallText', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('smallText', 'mobile');
            }
        }

        .date {
            font-size: getFontSize('copyright', 'desktop');

            @include breakpoint('tablet') {
                font-size: getFontSize('copyright', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('copyright', 'mobile');
            }
        }
    }

</style>
